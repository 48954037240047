import { createContext, PropsWithChildren, useContext } from 'react'
import { QuizResultWithData } from '../Components/Quiz/ProductsQuizState'

interface StorageData {
  QuizResult: QuizResultWithData
}

interface LocalStorageContext {
  save: <K extends keyof StorageData>(key: K, value: StorageData[K]) => void
  get: <K extends keyof StorageData>(key: K) => StorageData[K] | null
  remove: <K extends keyof StorageData>(key: K) => void
}

const LocalStorageContext = createContext<LocalStorageContext>({
  save: () => {},
  get: () => null,
  remove: () => {}
})

export function LocalStorageProvider(props: PropsWithChildren<{}>) {
  const save = <K extends keyof StorageData>(
    key: K,
    value: StorageData[K]
  ): void => {
    window.localStorage.setItem(key, JSON.stringify(value))
  }

  const get = <K extends keyof StorageData>(key: K): StorageData[K] | null => {
    const stringValue = window.localStorage.getItem(key)

    if (stringValue === null) {
      return null
    } else {
      try {
        const value = JSON.parse(stringValue) as StorageData[K]
        return value
      } catch (e) {
        return null
      }
    }
  }

  const remove = <K extends keyof StorageData>(key: K): void => {
    window.localStorage.removeItem(key)
  }

  return (
    <LocalStorageContext.Provider value={{ save, get, remove }}>
      {props.children}
    </LocalStorageContext.Provider>
  )
}

export function useLocalStorage(): LocalStorageContext {
  return useContext(LocalStorageContext)
}
