import {
  Answer,
  AnsweredQuestion,
  makeSelectQuestion,
  Question,
  SelectQuestion
} from './domain'
import { QuizHistory } from './QuizHistory'
import iconNetflixAddicted from '../../assets/icon-netflix-addicted.svg'
import iconOutdoor from '../../assets/icon-outdoor.svg'
import iconWorkaholic from '../../assets/icon-workaholic.svg'
import iconGlobetrotter from '../../assets/icon-globetrotter.svg'
import iconAvocadoToast from '../../assets/icon-avocado-toast.svg'
import iconFruttaPaneMarmellata from '../../assets/icon-frutta-pane-marmellata.svg'
import iconLatteBiscotti from '../../assets/icon-latte-biscotti.svg'
import iconCoffee from '../../assets/icon-coffee.svg'
import iconNonRicordo from '../../assets/icon-non-ricordo.svg'

type NameQuestionTag = 'NameQuestion'
type GenderQuestionTag = 'GenderQuestion'
type SkinTypeQuestionTag = 'SkinTypeQuestion'
type LifestyleQuestionTag = 'LifestyleQuestion'
type SportsQuestionTag = 'SportsQuestion'
type JobTravelQuestionTag = 'JobTravelQuestion'
type BreakfastQuestionTag = 'BreakfastQuestion'
type AgeQuestionTag = 'AgeQuestion'
type SkincareQuestionTag = 'SkincareQuestion'
type SkinConditionQuestionTag = 'SkinConditionQuestion'

export type QuestionTag =
  | NameQuestionTag
  | GenderQuestionTag
  | SkinTypeQuestionTag
  | LifestyleQuestionTag
  | SportsQuestionTag
  | JobTravelQuestionTag
  | BreakfastQuestionTag
  | AgeQuestionTag
  | SkincareQuestionTag
  | SkinConditionQuestionTag

type GenderAnswerTag = 'MaleAnswer' | 'FemaleAnswer' | 'OtherAnswer'

type PlaceAnswerTag =
  | 'CityAnswer'
  | 'CountryAnswer'
  | 'SeaAnswer'
  | 'MountainsAnswer'

type SkinTypeAnswerTag = 'RegularAnswer' | 'SensibleAnswer' | 'AllergicAnswer'

type LifestyleAnswerTag =
  | 'WorkaholicAnswer'
  | 'NetflixAddictedAnswer'
  | 'OutdoorLoverAnswer'
  | 'KingOfParkAnswer'
  | 'Globetrotter'

type SportsAnswerTag =
  | 'WhatAnswer'
  | 'JoggingAnswer'
  | 'PilatesAnswer'
  | 'YogaAnswer'
  | 'GymAnswer'
  | 'TrekkingAnswer'
  | 'BikingAnswer'
  | 'PadelAnswer'
  | 'SwimmingAnswer'

type JobTravelAnswerTag =
  | 'Foot'
  | 'Bike'
  | 'Car'
  | 'PublicTransport'
  | 'SmartWorking'
  | 'Unemployed'

type BreakfastAnswerTag =
  | 'AvocadoAnswer'
  | 'HealthyAnswer'
  | 'FatAnswer'
  | 'CoffeeAnswer'
  | 'NopeAnswer'

type AgeAnswerTag = '20to35Answer' | '36to45Answer' | '46upAnswer'
type SkincareAnswerTag = 'SlowAnswer' | 'FastAnswer'
type SkinConditionAnswerTag = 'DryAnswer' | 'FatAnswer'

export type AnswerTag =
  | GenderAnswerTag
  | PlaceAnswerTag
  | SkinTypeAnswerTag
  | LifestyleAnswerTag
  | SportsAnswerTag
  | JobTravelAnswerTag
  | BreakfastAnswerTag
  | AgeAnswerTag
  | SkincareAnswerTag
  | SkinConditionAnswerTag

interface AnswerWithPicture<A> extends Answer<A> {
  picture?: string
}

export type OpenQuestion<T> = Question<T, never, string> & {
  type: 'Open'
}
function makeOpenQuestion<T>(tag: T, text: string): OpenQuestion<T> {
  return {
    status: 'Unanswered',
    type: 'Open',
    tag,
    text
  }
}

export type CursorQuestion<Q, A> = Question<Q, A> & {
  type: 'Cursor'
  answers: Answer<A>[]
}
function makeCursorQuestion<Q, A>(
  tag: Q,
  text: string,
  answers: Answer<A>[]
): CursorQuestion<Q, A> {
  return {
    status: 'Unanswered',
    type: 'Cursor',
    tag,
    text,
    answers
  }
}

export type CardsWithPictureQuestion<Q, A> = Question<Q, A, A[]> & {
  type: 'CardsWithPicture'
  answers: AnswerWithPicture<A>[]
  maxAnswers: number
}
function makeCardsWithPicturesQuestion<Q, A>(
  tag: Q,
  text: string,
  answers: AnswerWithPicture<A>[],
  maxAnswers: number
): CardsWithPictureQuestion<Q, A> {
  return {
    status: 'Unanswered',
    type: 'CardsWithPicture',
    tag,
    text,
    answers,
    maxAnswers
  }
}

export type FortuneWheelQuestion<Q, A> = Question<Q, A> & {
  type: 'FortuneWheel'
  answers: Answer<A>[]
}
function makeFortuneWheelQuestion<Q, A>(
  tag: Q,
  text: string,
  answers: Answer<A>[]
): FortuneWheelQuestion<Q, A> {
  return {
    status: 'Unanswered',
    type: 'FortuneWheel',
    tag,
    text,
    answers
  }
}

type NameQuestion = OpenQuestion<NameQuestionTag>
const nameQuestion: NameQuestion = makeOpenQuestion(
  'NameQuestion',
  'Come ti chiami?'
)

type GenderQuestion = SelectQuestion<GenderQuestionTag, GenderAnswerTag>
const genderQuestion: GenderQuestion = makeSelectQuestion(
  'GenderQuestion',
  'Di che genere sei?',
  [
    { tag: 'MaleAnswer', text: 'Uomo' },
    { tag: 'FemaleAnswer', text: 'Donna' },
    { tag: 'OtherAnswer', text: 'Non binary' }
  ]
)

type SkinTypeQuestion = FortuneWheelQuestion<
  SkinTypeQuestionTag,
  SkinTypeAnswerTag
>
const skinTypeQuestion: SkinTypeQuestion = makeFortuneWheelQuestion(
  'SkinTypeQuestion',
  'Quale tra queste definizioni descrive meglio la tua pelle?',
  [
    { tag: 'RegularAnswer', text: 'Normale' },
    { tag: 'SensibleAnswer', text: 'Sensibile' },
    { tag: 'AllergicAnswer', text: 'Allergica' }
  ]
)

type LifestyleQuestion = CardsWithPictureQuestion<
  LifestyleQuestionTag,
  LifestyleAnswerTag
>
const lifestyleQuestion: LifestyleQuestion = makeCardsWithPicturesQuestion(
  'LifestyleQuestion',
  'Come ti definiresti?',
  [
    {
      tag: 'WorkaholicAnswer',
      text: 'Workaholic',
      picture: iconWorkaholic
    },
    {
      tag: 'OutdoorLoverAnswer',
      text: 'Outdoor lover',
      picture: iconOutdoor
    },
    {
      tag: 'NetflixAddictedAnswer',
      text: 'Netflix addicted',
      picture: iconNetflixAddicted
    },
    {
      tag: 'Globetrotter',
      text: 'Globetrotter',
      picture: iconGlobetrotter
    }
  ],
  1
)

type SportsQuestion = CardsWithPictureQuestion<
  SportsQuestionTag,
  SportsAnswerTag
>
const sportsQuestion: SportsQuestion = makeCardsWithPicturesQuestion(
  'SportsQuestion',
  'Che tipo di sport pratichi?',
  [
    { tag: 'JoggingAnswer', text: 'Corsa' },
    { tag: 'PilatesAnswer', text: 'Pilates / Yoga' },
    { tag: 'PadelAnswer', text: 'Padel' },
    { tag: 'GymAnswer', text: 'Palestra / Nuoto' },
    { tag: 'TrekkingAnswer', text: 'Trekking / Bici' },
    { tag: 'WhatAnswer', text: 'Di cosa stai parlando?' }
  ],
  1
)

type JobTravelQuestion = CardsWithPictureQuestion<
  JobTravelQuestionTag,
  JobTravelAnswerTag
>
const jobTravelQuestion: JobTravelQuestion = makeCardsWithPicturesQuestion(
  'JobTravelQuestion',
  'Come vai al lavoro?',
  [
    { tag: 'Foot', text: 'A piedi' },
    { tag: 'Bike', text: 'In bicicletta' },
    { tag: 'Car', text: 'In auto' },
    { tag: 'PublicTransport', text: 'Con i mezzi pubblici' },
    { tag: 'SmartWorking', text: 'Lavoro da casa' },
    { tag: 'Unemployed', text: 'Non lavoro' }
  ],
  1
)

type BreakfastQuestion = CardsWithPictureQuestion<
  BreakfastQuestionTag,
  BreakfastAnswerTag
>
const breakfastQuestion: BreakfastQuestion = makeCardsWithPicturesQuestion(
  'BreakfastQuestion',
  'La tua colazione ideale?',
  [
    {
      tag: 'AvocadoAnswer',
      text: 'Avocado toast, yogurt e tè verde',
      picture: iconAvocadoToast
    },
    {
      tag: 'HealthyAnswer',
      text: 'Frutta, pane e marmellata',
      picture: iconFruttaPaneMarmellata
    },
    {
      tag: 'FatAnswer',
      text: 'Latte e biscotti al cioccolato',
      picture: iconLatteBiscotti
    },
    {
      tag: 'CoffeeAnswer',
      text: 'Caffè di corsa',
      picture: iconCoffee
    },
    {
      tag: 'NopeAnswer',
      text: "Non ricordo l'ultima volta che l'ho fatta",
      picture: iconNonRicordo
    }
  ],
  1
)

type AgeQuestion = FortuneWheelQuestion<AgeQuestionTag, AgeAnswerTag>
const ageQuestion: AgeQuestion = makeFortuneWheelQuestion(
  'AgeQuestion',
  'Quanti anni hai?',
  [
    { tag: '20to35Answer', text: '20/35' },
    { tag: '36to45Answer', text: '36/45' },
    { tag: '46upAnswer', text: '46/100' }
  ]
)

type SkincareQuestion = CursorQuestion<SkincareQuestionTag, SkincareAnswerTag>
const skincareQuestion: SkincareQuestion = makeCursorQuestion(
  'SkincareQuestion',
  'Come vivi il tuo momento skincare?',
  [
    {
      tag: 'SlowAnswer',
      text: 'È la mia coccola: mi prendo tutto il tempo che serve.'
    },
    {
      tag: 'FastAnswer',
      text: 'So che la mia pelle ne ha bisogno, ma devo fare in fretta!'
    }
  ]
)

type SkinConditionQuestion = CursorQuestion<
  SkinConditionQuestionTag,
  SkinConditionAnswerTag
>
const skinConditionQuestion: SkinConditionQuestion = makeCursorQuestion(
  'SkinConditionQuestion',
  'In questo periodo come vedi la tua pelle?',
  [
    {
      tag: 'DryAnswer',
      text: 'Sento la pelle che «tira», soprattutto dopo la detersione.'
    },
    { tag: 'FatAnswer', text: 'Vedo la pelle spesso unta, opaca.' }
  ]
)

export type ProductTag =
  | 'CleansingGel'
  | 'ConcentratedPowerSerum'
  | 'EyeCream'
  | 'DaytimeTreatment'
  | 'DaytimeTreatmentUV'
  | 'NightTreatment'
  | 'FaceCream'
  | 'FaceGel'
  | 'Oil'

type ResultTag =
  | 'YoungSmartRoutineBlueLight'
  | 'YoungSmartRoutineSunLight'
  | 'YoungFullRoutineDrySkinBlueLight'
  | 'YoungFullRoutineDrySkinSunLight'
  | 'YoungFullRoutineFatSkinBlueLight'
  | 'YoungFullRoutineFatSkinSunLight'
  | 'AdultDrySkinBlueLight'
  | 'AdultDrySkinSunLight'
  | 'AdultFatSkinBlueLight'
  | 'AdultFatSkinSunLight'
  | 'OldBlueLight'
  | 'OldSunLight'
  | 'AllergicSkin'

export const ResultPath = {
  'always-on-the-run-young': true,
  'freedom-lover-young-smart-routine': true,
  'keep-fresh-young': true,
  'keep-bright-young': true,
  'beauty-routine-lover': true,
  'freedom-lover-young-complete-routine': true,
  'keep-fresh-adult': true,
  'keep-bright-adult': true,
  'always-on-the-run-adult': true,
  'freedom-lover-adult': true,
  'forever-young': true,
  'forever-young-uv': true,
  'protettiva-disarrosante': true
}

interface QuizResultNoData {
  type: 'QuizResultNoData'
  tag: ResultTag
}

export interface QuizResultWithData {
  type: 'QuizResultWithData'
  tag: ResultTag
  name: string
  gender: GenderAnswerTag
}

export type QuizResult = QuizResultNoData | QuizResultWithData

function makeResult(
  name: string,
  gender: GenderAnswerTag,
  tag: ResultTag
): QuizResultWithData {
  return { type: 'QuizResultWithData', name, gender, tag }
}

interface QuizState {
  type: 'Quiz'
  question: Question<QuestionTag, AnswerTag, unknown>
  history: QuizHistory<QuestionTag>
}

interface LoadingResultState {
  type: 'LoadingResult'
  result: QuizResultWithData
}

interface ResultState {
  type: 'Result'
  result: QuizResultWithData
}

type State = QuizState | LoadingResultState | ResultState

interface AnswerAction<QT extends QuestionTag, AT extends AnswerTag, A = AT> {
  type: 'Answer'
  question: Question<QT, AT, A>
  answer: A
}

export function answerAction<
  QT extends QuestionTag,
  AT extends AnswerTag,
  A = AT
>(question: Question<QT, AT, A>, answer: A): AnswerAction<QT, AT, A> {
  return { type: 'Answer', question, answer }
}

interface BackAction {
  type: 'Back'
}

export function backAction(): BackAction {
  return { type: 'Back' }
}

interface NextAction {
  type: 'Next'
}

export function nextAction(): NextAction {
  return { type: 'Next' }
}

interface ResultReadyAction {
  type: 'ResultReady'
}

export function resultReadyAction(): ResultReadyAction {
  return { type: 'ResultReady' }
}

type Action =
  | AnswerAction<QuestionTag, AnswerTag, unknown>
  | BackAction
  | NextAction
  | ResultReadyAction

export function initialState(): State {
  return {
    type: 'Quiz',
    question: nameQuestion as Question<QuestionTag, AnswerTag>,
    history: QuizHistory.start()
  }
}

export function resultTagToPath(tag: ResultTag): keyof typeof ResultPath {
  switch (tag) {
    case 'YoungSmartRoutineBlueLight':
      return 'always-on-the-run-young'
    case 'YoungSmartRoutineSunLight':
      return 'freedom-lover-young-smart-routine'
    case 'YoungFullRoutineDrySkinBlueLight':
      return 'keep-fresh-young'
    case 'YoungFullRoutineDrySkinSunLight':
      return 'keep-bright-young'
    case 'YoungFullRoutineFatSkinBlueLight':
      return 'beauty-routine-lover'
    case 'YoungFullRoutineFatSkinSunLight':
      return 'freedom-lover-young-complete-routine'
    case 'AdultDrySkinBlueLight':
      return 'keep-fresh-adult'
    case 'AdultDrySkinSunLight':
      return 'keep-bright-adult'
    case 'AdultFatSkinBlueLight':
      return 'always-on-the-run-adult'
    case 'AdultFatSkinSunLight':
      return 'freedom-lover-adult'
    case 'OldBlueLight':
      return 'forever-young'
    case 'OldSunLight':
      return 'forever-young-uv'
    case 'AllergicSkin':
      return 'protettiva-disarrosante'
  }
}

export function pathToResultTag(path: keyof typeof ResultPath): ResultTag {
  switch (path) {
    case 'always-on-the-run-young':
      return 'YoungSmartRoutineBlueLight'
    case 'freedom-lover-young-smart-routine':
      return 'YoungSmartRoutineSunLight'
    case 'keep-fresh-young':
      return 'YoungFullRoutineDrySkinBlueLight'
    case 'keep-bright-young':
      return 'YoungFullRoutineDrySkinSunLight'
    case 'beauty-routine-lover':
      return 'YoungFullRoutineFatSkinBlueLight'
    case 'freedom-lover-young-complete-routine':
      return 'YoungFullRoutineFatSkinSunLight'
    case 'keep-fresh-adult':
      return 'AdultDrySkinBlueLight'
    case 'keep-bright-adult':
      return 'AdultDrySkinSunLight'
    case 'always-on-the-run-adult':
      return 'AdultFatSkinBlueLight'
    case 'freedom-lover-adult':
      return 'AdultFatSkinSunLight'
    case 'forever-young':
      return 'OldBlueLight'
    case 'forever-young-uv':
      return 'OldSunLight'
    case 'protettiva-disarrosante':
      return 'AllergicSkin'
  }
}

export function reducer(state: State, action: Action): State {
  switch (state.type) {
    case 'LoadingResult':
      switch (action.type) {
        case 'Answer':
          return state
        case 'Back':
          return state
        case 'Next':
          return state
        case 'ResultReady':
          return {
            type: 'Result',
            result: state.result
          }
      }
    case 'Result':
      return state
    case 'Quiz':
      switch (action.type) {
        case 'Answer':
          return {
            ...state,
            question: {
              ...state.question,
              status: 'Answered',
              answer: action.answer
            },
            history: state.history.withAnswer(action.question, action.answer)
          }
        case 'Back':
          const previousQuestion = getPreviousStep(
            state.question,
            state.history
          )
          const previousAnswer = state.history.getItem(previousQuestion)

          return {
            ...state,
            question: previousAnswer
              ? {
                  ...previousQuestion,
                  status: 'Answered',
                  answer: previousAnswer
                }
              : previousQuestion
          }
        case 'Next':
          const latestAnswer = state.history.getItem(state.question)

          if (!latestAnswer) {
            return state
          } else {
            const nextStep = getNextStep(
              {
                ...state.question,
                status: 'Answered',
                answer: latestAnswer
              },
              state.history
            )

            switch (nextStep.type) {
              case 'QuizResultWithData':
                return { type: 'LoadingResult', result: nextStep }
              case 'CardsWithPicture':
              case 'Cursor':
              case 'FortuneWheel':
              case 'Open':
              case 'Select':
                const previousAnswerToNextQuestion =
                  state.history.getItem(nextStep)

                return {
                  ...state,
                  question: previousAnswerToNextQuestion
                    ? {
                        ...nextStep,
                        status: 'Answered',
                        answer: previousAnswerToNextQuestion
                      }
                    : nextStep
                }
            }
          }
        case 'ResultReady':
          return state
      }
  }
}

function getPreviousStep(
  currentQuestion: Question<QuestionTag, AnswerTag, unknown>,
  history: QuizHistory<QuestionTag>
): Question<QuestionTag, AnswerTag, unknown> {
  switch (currentQuestion.tag) {
    case 'NameQuestion':
      return nameQuestion as Question<QuestionTag, AnswerTag>
    case 'GenderQuestion':
      return nameQuestion as Question<QuestionTag, AnswerTag>
    case 'SkinTypeQuestion':
      return genderQuestion
    case 'LifestyleQuestion':
      return skinTypeQuestion
    case 'SportsQuestion':
      return lifestyleQuestion as Question<QuestionTag, AnswerTag>
    case 'JobTravelQuestion':
      return sportsQuestion
    case 'BreakfastQuestion':
      return jobTravelQuestion
    case 'AgeQuestion':
      return breakfastQuestion
    case 'SkinConditionQuestion':
      const age = history.getItem(ageQuestion)

      if (!age) {
        return ageQuestion
      } else {
        switch (age) {
          case '20to35Answer':
            return skincareQuestion
          case '36to45Answer':
          case '46upAnswer':
            return ageQuestion
        }
      }
    case 'SkincareQuestion':
      return ageQuestion
  }
}

function getNextStep(
  currentQuestion: AnsweredQuestion<QuestionTag, AnswerTag, unknown>,
  history: QuizHistory<QuestionTag>
): Question<QuestionTag, AnswerTag, unknown> | QuizResultWithData {
  switch (currentQuestion.tag) {
    case 'NameQuestion':
      return genderQuestion
    case 'GenderQuestion':
      return skinTypeQuestion
    case 'SkinTypeQuestion':
      const name = history.getItem(nameQuestion)
      const gender = history.getItem(genderQuestion)

      if (!name) {
        return nameQuestion
      } else if (!gender) {
        return genderQuestion
      } else {
        const answer = currentQuestion.answer as SkinTypeAnswerTag

        if (answer === 'AllergicAnswer') {
          return makeResult(name, gender, 'AllergicSkin')
        } else {
          return lifestyleQuestion
        }
      }
    case 'LifestyleQuestion':
      return sportsQuestion
    case 'SportsQuestion':
      return jobTravelQuestion
    case 'JobTravelQuestion':
      return breakfastQuestion
    case 'BreakfastQuestion':
      return ageQuestion
    case 'AgeQuestion':
      switch (currentQuestion.answer as AgeAnswerTag) {
        case '20to35Answer':
          return skincareQuestion
        case '36to45Answer':
          return skinConditionQuestion
        case '46upAnswer':
          return getOldPeopleResult(history)
      }
    case 'SkinConditionQuestion': {
      const ageAnswer = history.getItem(ageQuestion)
      const lifestyleAnswers = history.getItem(lifestyleQuestion)

      if (!lifestyleAnswers) {
        return lifestyleQuestion
      } else if (!ageAnswer) {
        return ageQuestion
      } else {
        const name = history.getItem(nameQuestion)
        const gender = history.getItem(genderQuestion)
        const lifestyle = getLifestyle(lifestyleAnswers)

        if (!name) {
          return nameQuestion
        } else if (!gender) {
          return genderQuestion
        } else {
          switch (ageAnswer) {
            case '20to35Answer':
              const skinCondition = history.getItem(skinConditionQuestion)

              if (!skinCondition) {
                return skinConditionQuestion
              } else {
                switch (skinCondition) {
                  case 'DryAnswer':
                    switch (lifestyle) {
                      case 'Indoor':
                        return makeResult(
                          name,
                          gender,
                          'YoungFullRoutineDrySkinBlueLight'
                        )
                      case 'Outdoor':
                        return makeResult(
                          name,
                          gender,
                          'YoungFullRoutineDrySkinSunLight'
                        )
                    }
                  case 'FatAnswer':
                    switch (lifestyle) {
                      case 'Indoor':
                        return makeResult(
                          name,
                          gender,
                          'YoungFullRoutineFatSkinBlueLight'
                        )
                      case 'Outdoor':
                        return makeResult(
                          name,
                          gender,
                          'YoungFullRoutineFatSkinSunLight'
                        )
                    }
                }
              }
            case '36to45Answer':
              switch (currentQuestion.answer as SkinConditionAnswerTag) {
                case 'DryAnswer':
                  switch (lifestyle) {
                    case 'Indoor':
                      return makeResult(name, gender, 'AdultDrySkinBlueLight')
                    case 'Outdoor':
                      return makeResult(name, gender, 'AdultDrySkinSunLight')
                  }
                case 'FatAnswer':
                  switch (lifestyle) {
                    case 'Indoor':
                      return makeResult(name, gender, 'AdultFatSkinBlueLight')
                    case 'Outdoor':
                      return makeResult(name, gender, 'AdultFatSkinSunLight')
                  }
              }
            case '46upAnswer':
              return getOldPeopleResult(history)
          }
        }
      }
    }
    case 'SkincareQuestion': {
      const name = history.getItem(nameQuestion)
      const gender = history.getItem(genderQuestion)
      const lifestyleAnswers = history.getItem(lifestyleQuestion)

      if (!name) {
        return nameQuestion
      } else if (!gender) {
        return genderQuestion
      } else if (!lifestyleAnswers) {
        return lifestyleQuestion
      } else {
        const lifestyle = getLifestyle(lifestyleAnswers)

        switch (currentQuestion.answer as SkincareAnswerTag) {
          case 'SlowAnswer':
            return skinConditionQuestion
          case 'FastAnswer':
            switch (lifestyle) {
              case 'Indoor':
                return makeResult(name, gender, 'YoungSmartRoutineBlueLight')
              case 'Outdoor':
                return makeResult(name, gender, 'YoungSmartRoutineSunLight')
            }
        }
      }
    }
  }
}

function getLifestyle(answers: LifestyleAnswerTag[]): 'Indoor' | 'Outdoor' {
  const score = answers.reduce(
    // eslint-disable-next-line array-callback-return
    (res, answer) => {
      switch (answer) {
        case 'KingOfParkAnswer':
        case 'OutdoorLoverAnswer':
        case 'Globetrotter':
          return { ...res, outdoor: res.outdoor + 1 }
        case 'NetflixAddictedAnswer':
        case 'WorkaholicAnswer':
          return { ...res, indoor: res.indoor + 1 }
      }
    },
    { indoor: 0, outdoor: 0 }
  )

  if (score.indoor >= score.outdoor) {
    return 'Indoor'
  } else {
    return 'Outdoor'
  }
}

function getOldPeopleResult(history: QuizHistory<QuestionTag>) {
  const name = history.getItem(nameQuestion)
  const gender = history.getItem(genderQuestion)
  const lifestyleAnswers = history.getItem(lifestyleQuestion)

  if (!name) {
    return nameQuestion
  } else if (!gender) {
    return genderQuestion
  } else if (!lifestyleAnswers) {
    return lifestyleQuestion
  } else {
    const lifestyle = getLifestyle(lifestyleAnswers)

    switch (lifestyle) {
      case 'Indoor':
        return makeResult(name, gender, 'OldBlueLight')
      case 'Outdoor':
        return makeResult(name, gender, 'OldSunLight')
    }
  }
}
