import { Container } from '../Container/Container'
import logo from '../../assets/logo-ceramol-iage-color.png'
import logo2x from '../../assets/logo-ceramol-iage-color@2x.png'
import './Footer.css'

export function Footer() {
  return (
    <div className="Footer">
      <Container>
        <img
          src={logo}
          srcSet={`${logo} 1x, ${logo2x} 2x`}
          alt="Ceramol i-AGE"
        />
        <p>
          © {new Date().getFullYear()} Unifarco S.P.A. 62, Cal Longa 32035 S.
          Giustina BL Italy - P. Iva 00595530254 &nbsp;-&nbsp;
          <a
            href="https://ceramol.it/pages/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          &nbsp;-&nbsp;
          <a
            href="https://ceramol.it/collections/i-age?utm_source=LP-iAGE"
            target="_blank"
            rel="noreferrer"
          >
            Compra online
          </a>
        </p>
      </Container>
    </div>
  )
}
