import { Question } from './domain'

export class QuizHistory<Q extends string> {
  private readonly history: Partial<Record<Q, unknown>>

  private constructor(history: Partial<Record<Q, unknown>>) {
    this.history = history
  }

  public static start<Q extends string>(): QuizHistory<Q> {
    return new QuizHistory({})
  }

  public withAnswer<AT, A = AT>(
    question: Question<Q, AT, A>,
    answer: A
  ): QuizHistory<Q> {
    return new QuizHistory<Q>({
      ...this.history,
      [question.tag]: answer
    })
  }

  public getItem<AT, A = AT>(question: Question<Q, AT, A>): A | null {
    return (this.history[question.tag] as A) || null
  }

  public getHistory(): Partial<Record<Q, unknown>> {
    return this.history
  }
}
