import { useEffect } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/logo-ceramol-iage-color.png'
import logo2x from '../../assets/logo-ceramol-iage-color@2x.png'
import { Container } from '../Container/Container'
import { SlideDownIcon } from '../SlideDownIcon/SlideDownIcon'
import iconPin from '../../assets/icon-pin.svg'
import iconShop from '../../assets/icon-shop.svg'
import './Menu.css'

export function Menu() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const onResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  const shouldMenuContentBeVisible = windowWidth < 1024

  const menuContent = (
    <div className="content">
      <ul>
        <li>
          <a
            href="https://ceramol.it/pages/trova-la-tua-farmacia?utm_source=LP-iAGE"
            target="_blank"
            rel="noreferrer"
          >
            <img src={iconPin} alt="Trova farmacia" />
            <span>Trova farmacia</span>
          </a>
        </li>
        <li>
          <a
            href="https://ceramol.it/collections/i-age?utm_source=LP-iAGE"
            target="_blank"
            rel="noreferrer"
          >
            <img src={iconShop} alt="Shop online" />
            <span>Shop online</span>
          </a>
        </li>
      </ul>
    </div>
  )

  return (
    <>
      <header className="Menu">
        <Container>
          <Link to="/">
            <img
              src={logo}
              srcSet={`${logo} 1x, ${logo2x} 2x`}
              alt="Ceramol i-AGE"
              className="logo"
              height="36"
            />
          </Link>
          {shouldMenuContentBeVisible ? (
            <p>
              <SlideDownIcon
                isOpen={isMenuOpen}
                color="#000"
                action={() => setIsMenuOpen(isMenuOpen => !isMenuOpen)}
              />
            </p>
          ) : (
            menuContent
          )}
        </Container>
      </header>
      {shouldMenuContentBeVisible && (
        <div className={`MenuContent${isMenuOpen ? ' visible' : ''}`}>
          {menuContent}
        </div>
      )}
    </>
  )
}
