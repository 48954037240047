import { Container } from '../Container/Container'
import { Heading } from '../Heading/Heading'
import backgroundImage from '../../assets/ceramol-iage-map-background.png'
import './OverFooter.css'
import { Button } from '../Button/Button'

export function OverFooter() {
  return (
    <div
      className="OverFooter"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <Container>
        <Heading type="Section">
          Trovi{' '}
          <strong>
            <span className="ceramol">Ceramol</span> i-AGE
          </strong>{' '}
          in farmacia
        </Heading>
        <Button
          type="Link"
          url="https://ceramol.it/pages/trova-la-tua-farmacia?utm_source=LP-iAGE"
          label="cerca quella più vicina a te"
          target="_blank"
          rel="noreferrer"
        />
      </Container>
    </div>
  )
}
