import './ProductsResult.css'
import cleansingGel from '../../assets/ceramol-iage-cleansing-gel.png'
import powerSerum from '../../assets/ceramol-iage-power-serum.png'
import eyeCream from '../../assets/ceramol-iage-eye-cream.png'
import daytimeTreatment from '../../assets/ceramol-iage-daytime-treatment.png'
import daytimeTreatmentUV from '../../assets/ceramol-iage-daytime-treatment-uv.png'
import nightTreatment from '../../assets/ceramol-iage-night-treatment.png'
import faceCream from '../../assets/ceramol-crema-viso.png'
import faceGel from '../../assets/ceramol-gel-viso.png'
import oil from '../../assets/ceramol-olio-detergente.png'
import { ProductTag, QuizResult } from './ProductsQuizState'
import alwaysOnTheRun from '../../assets/ceramol-iage-result-always-on-the-run.png'
import beautyRoutineLover from '../../assets/ceramol-iage-result-beauty-routine-lover.png'
import foreverYoung from '../../assets/ceramol-iage-result-forever-young.png'
import freedomLover from '../../assets/ceramol-iage-result-freedom-lover.png'
import keepBright from '../../assets/ceramol-iage-result-keep-bright.png'
import keepFresh from '../../assets/ceramol-iage-result-keep-fresh.png'
import protettivaDisarrossante from '../../assets/ceramol-iage-result-protettiva-disarrossante.png'
import intelligentTipBackground from '../../assets/ceramol-iage-intelligent-tip-background.png'
import { Button } from '../Button/Button'
import { OverFooter } from '../OverFooter/OverFooter'
import { Footer } from '../Footer/Footer'
import { Menu } from '../Menu/Menu'
import { Container } from '../Container/Container'
import { useLocalStorage } from '../../contexts/LocalStorage'
import { useEffect } from 'react'

interface Props {
  result: QuizResult
}

interface ResultPicturesData {
  src: string
  productNumber: string
  productName: string
  url: string
}

interface ResultContent {
  icon: string
  title: string
  description: string
  tip: string
}

export function ProductsResult(props: Props) {
  const { get, remove } = useLocalStorage()
  const previousResult = get('QuizResult')
  const name = previousResult?.name ?? null

  const genderLetter = (() => {
    if (!previousResult) {
      return '*'
    }

    switch (previousResult.gender) {
      case 'MaleAnswer':
        return 'o'
      case 'FemaleAnswer':
        return 'a'
      case 'OtherAnswer':
        return '*'
    }
  })()

  const alwaysOnTheRunContent: ResultContent = {
    icon: alwaysOnTheRun,
    title: 'Always on the run!',
    description: `per te che hai sempre poco tempo da dedicare a te stess${genderLetter}, ma cerchi prodotti smart che si prendano cura del benessere della tua pelle, ti consigliamo questa routine di <strong><span class="ceramol">Ceramol</span> i-AGE</strong>.`,
    tip: 'È importante prendersi cura della pelle sin da giovani. Per questo, <strong>già a partire da 20 anni</strong> è consigliabile utilizzare prodotti con formule innovative che proteggono la pelle dallo stress quotidiano.'
  }

  const alwaysOnTheRunAdultContent: ResultContent = {
    icon: alwaysOnTheRun,
    title: 'Always on the run!',
    description: `per te che passi tante ore davanti a uno schermo e cerchi prodotti smart che si prendano cura del benessere della tua pelle, ti consigliamo questa routine di <strong><span class="ceramol">Ceramol</span> i-AGE</strong>.`,
    tip: `La pelle intorno agli occhi è particolarmente sollecitata ed è la prima a mostrare i segni dell'età. Per questo dopo i 35 anni è consigliabile inserire nella propria routine anche <strong>un prodotto specifico per il contorno occhi</strong>.`
  }

  const freedomLoverContent: ResultContent = {
    icon: freedomLover,
    title: 'Freedom Lover!',
    description: `per te che ami vivere all'aria aperta e cerchi prodotti che proteggano la tua pelle dai raggi UV e dallo stress, ti consigliamo questa routine di <strong><span class="ceramol">Ceramol</span> i-AGE</strong>.`,
    tip: 'È importante prendersi cura della pelle sin da giovani. Per questo, <strong>già a partire da 20 anni</strong> è consigliabile utilizzare una crema protettiva solare per la prevenzione quotidiana del photoaging.'
  }

  const freedomLoverCompleteRoutineContent: ResultContent = {
    icon: freedomLover,
    title: 'Freedom Lover!',
    description: `per te che ami vivere all'aria aperta e cerchi prodotti smart e confortevoli che proteggano la pelle da raggi UV e stress senza appesantirla, ti consigliamo questa routine di <strong><span class="ceramol">Ceramol</span> i-AGE</strong>.`,
    tip: 'È importante prendersi cura della pelle sin da giovani. Per questo, <strong>già a partire da 20 anni</strong> è consigliabile utilizzare una crema protettiva solare per la prevenzione quotidiana del photoaging.'
  }

  const freedomLoverAdultContent: ResultContent = {
    icon: freedomLover,
    title: 'Freedom Lover!',
    description: `per te che ami vivere all'aria aperta e cerchi prodotti che non appesantiscano la pelle, ma la rendano più fresca, idratata e protetta, ti consigliamo questa routine di <strong><span class="ceramol">Ceramol</span> i-AGE</strong>.`,
    tip: `La pelle intorno agli occhi è particolarmente sollecitata ed è la prima a mostrare i segni dell'età. Per questo dopo i 35 anni è consigliabile inserire nella propria routine anche <strong>un prodotto specifico per il contorno occhi</strong>.`
  }

  const keepFreshContent: ResultContent = {
    icon: keepFresh,
    title: 'Keep Fresh & Carry On!',
    description:
      'per te che vivi la skincare come una coccola quotidiana e cerchi prodotti che si prendano cura della tua pelle con ingredienti ricchi e idratanti, ti consigliamo questa routine di <strong><span class="ceramol">Ceramol</span> i-AGE</strong>.',
    tip: 'È importante prendersi cura della pelle sin da giovani. Per questo, <strong>già a partire dai 20 anni</strong> è consigliabile utilizzare prodotti con formule innovative che proteggono la pelle dallo stress quotidiano.'
  }

  const keepFreshAdultContent: ResultContent = {
    icon: keepFresh,
    title: 'Keep Fresh & Carry On!',
    description: `per te che passi tante ore davanti a uno schermo e cerchi prodotti smart che si prendano cura del benessere della tua pelle, ti consigliamo questa routine di <strong><span class="ceramol">Ceramol</span> i-AGE</strong>.`,
    tip: "La pelle intorno agli occhi è particolarmente sollecitata ed è la prima a mostrare i segni dell'età. Per questo dopo i 35 anni è consigliabile inserire nella propria routine anche <strong>un prodotto specifico per il contorno occhi</strong>."
  }

  const keepBrightContent: ResultContent = {
    icon: keepBright,
    title: 'Keep Bright & Carry On!',
    description: `per te che ami vivere all'aria aperta e cerchi prodotti che nutrano la tua pelle con formule ricche e idratanti, ti consigliamo questa routine di <strong><span class="ceramol">Ceramol</span> i-AGE</strong>.`,
    tip: 'È importante prendersi cura della pelle sin da giovani. Per questo, <strong>già a partire dai 20 anni</strong> è consigliabile utilizzare una crema protettiva solare per la prevenzione del photoaging.'
  }

  const keepBrightAdultContent: ResultContent = {
    icon: keepBright,
    title: 'Keep Bright & Carry On!',
    description: `per te che ami vivere all'aria aperta e cerchi prodotti con formule ricche per una pelle più idratata e protetta ti consigliamo questa routine di <strong><span class="ceramol">Ceramol</span> i-AGE</strong>.`,
    tip: "La pelle intorno agli occhi è particolarmente sollecitata ed è la prima a mostrare i segni dell'età. Per questo dopo i 35 anni è consigliabile inserire nella propria routine anche <strong>un prodotto specifico per il contorno occhi</strong>."
  }

  const beautyRoutineLoverContent: ResultContent = {
    icon: beautyRoutineLover,
    title: 'Beauty Routine Lover!',
    description: `per te che vivi la skincare come una coccola quotidiana e cerchi prodotti smart che non appesantiscano la pelle, ma la rendano più fresca e idratata, ti consigliamo questa routine di <strong><span class="ceramol">Ceramol</span> i-AGE</strong>.`,
    tip: 'È importante prendersi cura della pelle sin da giovani. Per questo, <strong>già a partire da 20 anni</strong> è consigliabile utilizzare prodotti con formule innovative che proteggono la pelle dallo stress quotidiano.'
  }

  const foreverYoungBlueLightContent: ResultContent = {
    icon: foreverYoung,
    title: 'Forever Young!',
    description: `per te che vivi la skincare come una coccola quotidiana e vuoi immergerti in un rituale di bellezza e benessere, ti consigliamo questa routine di <strong><span class="ceramol">Ceramol</span> i-AGE</strong>.`,
    tip: 'Dopo i 45 anni è sempre consigliabile inserire nella propria routine anche <strong>una crema notte</strong> che aiuta la pelle a recuperare lo stress accumulato durante il giorno.'
  }

  const foreverYoungSunLightContent: ResultContent = {
    icon: foreverYoung,
    title: 'Forever Young!',
    description: `per te che trascorri molte ore all'aria aperta e vivi la skin care come una coccola quotidiana per il benessere della pelle, ti consigliamo questa routine di <strong><span class="ceramol">Ceramol</span> i-AGE</strong>.`,
    tip: 'Dopo i 45 anni è sempre consigliabile inserire nella propria routine anche <strong>una crema notte</strong> che aiuta la pelle a recuperare lo stress accumulato durante il giorno.'
  }

  const protettivaDisarrossanteContent: ResultContent = {
    icon: protettivaDisarrossante,
    title: 'Protettiva e Disarrossante!',
    description:
      'Per te che hai la pelle allergica e hai bisogno di prodotti ad altissima tollerabilità, ti consigliamo Ceramol Gel Viso e Ceramol Crema Viso.',
    tip: 'Per le pelli allergiche è sempre consigliabile inserire nella propria routine prodotti senza profumo ed estremamente delicati sulla pelle.'
  }

  const products: ProductTag[] = (() => {
    switch (props.result.tag) {
      case 'YoungSmartRoutineBlueLight':
        return [
          'CleansingGel' as const,
          'ConcentratedPowerSerum' as const,
          'DaytimeTreatment' as const
        ]
      case 'YoungSmartRoutineSunLight':
        return [
          'CleansingGel' as const,
          'ConcentratedPowerSerum' as const,
          'DaytimeTreatmentUV' as const
        ]
      case 'YoungFullRoutineDrySkinBlueLight':
        return [
          'CleansingGel' as const,
          'ConcentratedPowerSerum' as const,
          'EyeCream' as const,
          'DaytimeTreatment' as const,
          'NightTreatment' as const
        ]
      case 'YoungFullRoutineDrySkinSunLight':
        return [
          'CleansingGel' as const,
          'ConcentratedPowerSerum' as const,
          'EyeCream' as const,
          'DaytimeTreatmentUV' as const,
          'NightTreatment' as const
        ]
      case 'YoungFullRoutineFatSkinBlueLight':
        return [
          'CleansingGel' as const,
          'ConcentratedPowerSerum' as const,
          'EyeCream' as const,
          'DaytimeTreatment' as const
        ]
      case 'YoungFullRoutineFatSkinSunLight':
        return [
          'CleansingGel' as const,
          'ConcentratedPowerSerum' as const,
          'EyeCream' as const,
          'DaytimeTreatmentUV' as const
        ]
      case 'AdultDrySkinBlueLight':
        return [
          'CleansingGel' as const,
          'ConcentratedPowerSerum' as const,
          'EyeCream' as const,
          'DaytimeTreatment' as const,
          'NightTreatment' as const
        ]
      case 'AdultDrySkinSunLight':
        return [
          'CleansingGel' as const,
          'ConcentratedPowerSerum' as const,
          'EyeCream' as const,
          'DaytimeTreatmentUV' as const,
          'NightTreatment' as const
        ]
      case 'AdultFatSkinBlueLight':
        return [
          'CleansingGel' as const,
          'ConcentratedPowerSerum' as const,
          'EyeCream' as const,
          'DaytimeTreatment' as const
        ]
      case 'AdultFatSkinSunLight':
        return [
          'CleansingGel' as const,
          'ConcentratedPowerSerum' as const,
          'EyeCream' as const,
          'DaytimeTreatmentUV' as const
        ]
      case 'OldBlueLight':
        return [
          'CleansingGel' as const,
          'ConcentratedPowerSerum' as const,
          'EyeCream' as const,
          'DaytimeTreatment' as const,
          'NightTreatment' as const
        ]
      case 'OldSunLight':
        return [
          'CleansingGel' as const,
          'ConcentratedPowerSerum' as const,
          'EyeCream' as const,
          'DaytimeTreatmentUV' as const,
          'NightTreatment' as const
        ]
      case 'AllergicSkin':
        return ['FaceCream' as const, 'FaceGel' as const, 'Oil' as const]
    }
  })()

  const content: ResultContent = (() => {
    switch (props.result.tag) {
      case 'YoungSmartRoutineBlueLight':
        return alwaysOnTheRunContent
      case 'YoungSmartRoutineSunLight':
        return freedomLoverContent
      case 'YoungFullRoutineDrySkinBlueLight':
        return keepFreshContent
      case 'YoungFullRoutineDrySkinSunLight':
        return keepBrightContent
      case 'YoungFullRoutineFatSkinBlueLight':
        return beautyRoutineLoverContent
      case 'YoungFullRoutineFatSkinSunLight':
        return freedomLoverCompleteRoutineContent
      case 'AdultDrySkinBlueLight':
        return keepFreshAdultContent
      case 'AdultDrySkinSunLight':
        return keepBrightAdultContent
      case 'AdultFatSkinBlueLight':
        return alwaysOnTheRunAdultContent
      case 'AdultFatSkinSunLight':
        return freedomLoverAdultContent
      case 'OldBlueLight':
        return foreverYoungBlueLightContent
      case 'OldSunLight':
        return foreverYoungSunLightContent
      case 'AllergicSkin':
        return protettivaDisarrossanteContent
    }
  })()

  const figures: ResultPicturesData[] =
    // eslint-disable-next-line array-callback-return
    products.map(tag => {
      switch (tag) {
        case 'CleansingGel':
          return {
            src: cleansingGel,
            productNumber: '0',
            productName: 'Cleansing gel',
            url: 'https://ceramol.it/products/ceramol-i-age-cleansing-gel'
          }
        case 'ConcentratedPowerSerum':
          return {
            src: powerSerum,
            productNumber: '1',
            productName: 'Concentrated power serum',
            url: 'https://ceramol.it/products/ceramol-i-age-concentrated-power-serum'
          }
        case 'EyeCream':
          return {
            src: eyeCream,
            productNumber: '2',
            productName: 'Eye cream',
            url: 'https://ceramol.it/products/ceramol-i-age-eye-cream'
          }
        case 'DaytimeTreatment':
          return {
            src: daytimeTreatment,
            productNumber: '3',
            productName: 'Daytime treatment',
            url: 'https://ceramol.it/products/ceramol-i-age-daytime-treatment'
          }
        case 'DaytimeTreatmentUV':
          return {
            src: daytimeTreatmentUV,
            productNumber: '3UV',
            productName: 'Daytime treatment UV',
            url: 'https://ceramol.it/products/ceramol-i-age-uv-daytime-treatment'
          }
        case 'NightTreatment':
          return {
            src: nightTreatment,
            productNumber: '4',
            productName: 'Night treatment',
            url: 'https://ceramol.it/products/ceramol-i-age-night-treatment'
          }
        case 'FaceCream':
          return {
            src: faceCream,
            productNumber: '0',
            productName: 'Ceramol crema viso',
            url: 'https://ceramol.it/products/ceramol-crema-viso'
          }
        case 'FaceGel':
          return {
            src: faceGel,
            productNumber: '1',
            productName: 'Ceramol gel viso',
            url: 'https://ceramol.it/products/ceramol-gel-viso'
          }
        case 'Oil':
          return {
            src: oil,
            productNumber: '2',
            productName: 'Ceramol olio detergente viso-corpo',
            url: 'https://ceramol.it/products/olio-detergente-viso-corpo'
          }
      }
    })

  useEffect(() => {
    const onWindowUnload = () => {
      if (previousResult) {
        remove('QuizResult')
      }
    }

    window.addEventListener('unload', onWindowUnload)

    return () => {
      window.removeEventListener('unload', onWindowUnload)
    }
  }, [remove, previousResult])

  const emailSubject = encodeURIComponent(
    'Ceramol i-AGE - Inizia la tua routine intelligente'
  )

  const url = (() => {
    switch (props.result.tag) {
      case 'YoungSmartRoutineBlueLight':
      case 'YoungSmartRoutineSunLight':
      case 'YoungFullRoutineDrySkinBlueLight':
      case 'YoungFullRoutineDrySkinSunLight':
      case 'YoungFullRoutineFatSkinBlueLight':
      case 'YoungFullRoutineFatSkinSunLight':
      case 'AdultDrySkinBlueLight':
      case 'AdultDrySkinSunLight':
      case 'AdultFatSkinBlueLight':
      case 'AdultFatSkinSunLight':
      case 'OldBlueLight':
      case 'OldSunLight':
        return 'https://ceramol.it/collections/i-age'
      case 'AllergicSkin':
        return ' https://ceramol.it/collections/pelle-allergica'
    }
  })()

  const emailBody = encodeURIComponent(
    `Ciao! Grazie per aver partecipato al nostro test. A questo link trovi l'intelligent routine "${content.title}" pensata per te e per la tua pelle! ${url}?utm_source=LP-iAGE&utm_medium=Quiz`
  )

  return (
    <>
      <Menu />
      <Container className="ProductsResult">
        <div className="content">
          <h2>the intelligent routine</h2>
          <img src={content.icon} alt={content.title} />
          <h3>{content.title}</h3>
          {name ? <p>Ciao {name},</p> : null}
          <p dangerouslySetInnerHTML={{ __html: content.description }} />
        </div>

        <div className="products">
          {figures.map(figure => (
            <div className="product" key={figure.productNumber}>
              <a
                href={`${figure.url}?utm_source=LP-iAGE&utm_medium=Quiz`}
                target="_blank"
                rel="noreferrer"
              >
                <img src={figure.src} alt={figure.productName} />
                <h3>
                  <span className="number">{figure.productNumber}.</span>
                  {figure.productName}
                </h3>
              </a>
            </div>
          ))}
        </div>

        <div className="links">
          <Button
            type="Link"
            url="https://ceramol.it/pages/trova-la-tua-farmacia?utm_source=LP-iAGE"
            label="Trovi Ceramol i-AGE in farmacia"
          />
          <Button
            type="Link"
            url={`mailto:?subject=${emailSubject}&body=${emailBody}`}
            label="Inviami la routine via e-mail"
          />
        </div>
      </Container>

      <div
        className="products-result-tip"
        style={{ backgroundImage: `url(${intelligentTipBackground})` }}
      >
        <Container>
          <h2>
            intelligent
            <br />
            tip!
          </h2>
          <p dangerouslySetInnerHTML={{ __html: content.tip }} />
        </Container>
      </div>
      <OverFooter />
      <Footer />
    </>
  )
}
