import { CSSProperties } from 'react'
import './Heading.css'

interface SectionHeadingProps {
  type: 'Section'
  negative?: boolean
  children: string | Array<string | JSX.Element>
  style?: CSSProperties
}

interface OtherHeadingProps {
  type: 'Paragraph' | 'Description'
  children: string | Array<string | JSX.Element>
  style?: CSSProperties
}

type Props = OtherHeadingProps | SectionHeadingProps

export function Heading(props: Props) {
  switch (props.type) {
    case 'Paragraph':
      return (
        <h2 className="Heading" style={props.style}>
          {props.children}
        </h2>
      )
    case 'Section':
      return (
        <h3
          className={`Heading${props.negative ? ' negative' : ''}`}
          style={props.style}
        >
          {props.children}
        </h3>
      )
    case 'Description':
      return (
        <h4 className="Heading" style={props.style}>
          {props.children}
        </h4>
      )
  }
}
