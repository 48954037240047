import './SlideDownIcon.css'

interface Props {
  color: string
  isOpen: boolean
  action: () => unknown
}

export function SlideDownIcon(props: Props) {
  return (
    <button
      className={`SlideDownIcon${props.isOpen ? ' open' : ''}`}
      onClick={props.action}
    >
      <span className="icon" style={{ borderColor: props.color }} />
    </button>
  )
}
