import { lazy, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import {
  pathToResultTag,
  ResultPath
} from './Components/Quiz/ProductsQuizState'
import { ProductsResult } from './Components/Quiz/ProductsResult'
import { LocalStorageProvider } from './contexts/LocalStorage'

const HomePage = lazy(() => import('./HomePage'))
const SkinSaverPage = lazy(() => import('./SkinSaverPage'))
const IntelligentRoutinePage = lazy(() => import('./IntelligentRoutinePage'))

export function App() {
  return (
    <Suspense fallback="">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/skin-saver.html" element={<SkinSaverPage />} />
          <Route
            path="/intelligent-routine.html"
            element={
              <LocalStorageProvider>
                <IntelligentRoutinePage />
              </LocalStorageProvider>
            }
          />
          {Object.keys(ResultPath).map(key => {
            const path = key as keyof typeof ResultPath

            return (
              <Route
                key={path}
                path={`/${path}.html`}
                element={
                  <LocalStorageProvider>
                    <ProductsResult
                      result={{
                        type: 'QuizResultNoData',
                        tag: pathToResultTag(path)
                      }}
                    />
                  </LocalStorageProvider>
                }
              />
            )
          })}
        </Routes>
      </BrowserRouter>
    </Suspense>
  )
}
