type QuestionType =
  | 'Open'
  | 'Select'
  | 'Cursor'
  | 'CardsWithPicture'
  | 'FortuneWheel'

interface BaseQuestion<T> {
  tag: T
  type: QuestionType
  text: string
}

export interface Answer<T> {
  tag: T
  text: string
}

export interface UnansweredQuestion<Q> extends BaseQuestion<Q> {
  status: 'Unanswered'
}

export interface AnsweredQuestion<Q, A, T = A> extends BaseQuestion<Q> {
  answer: T
  status: 'Answered'
}

export type Question<Q, A, T = A> =
  | UnansweredQuestion<Q>
  | AnsweredQuestion<Q, A, T>

export type SelectQuestion<Q, A> = Question<Q, A> & {
  type: 'Select'
  answers: Answer<A>[]
}

export type AnsweredSelectQuestion<Q, A> = SelectQuestion<Q, A> &
  AnsweredQuestion<Q, A>

export interface AnswerSelectQuestionAction<Q, A> {
  type: 'AnswerSelectQuestion'
  question: SelectQuestion<Q, A>
  answer: A
}

export function answerAction<Q, A>(
  question: SelectQuestion<Q, A>,
  answer: A
): AnswerSelectQuestionAction<Q, A> {
  return {
    type: 'AnswerSelectQuestion',
    question,
    answer
  }
}

export function makeSelectQuestion<Q, A>(
  tag: Q,
  text: string,
  answers: Answer<A>[]
): SelectQuestion<Q, A> {
  return { type: 'Select', status: 'Unanswered', tag, text, answers }
}

export function toAnsweredQuestion<
  QT,
  AT,
  Q extends Question<QT, AT, A>,
  A = AT
>(question: Q, answer: A): Q & AnsweredQuestion<QT, AT, A> {
  return {
    ...question,
    status: 'Answered',
    answer
  } as Q & AnsweredQuestion<QT, AT, A>
}

export interface CurrentSlideNumber {
  number: number
  totalCount: number
}
