import { CSSProperties, PropsWithChildren } from 'react'
import './Container.css'

interface Props
  extends PropsWithChildren<{ className?: string; style?: CSSProperties }> {}

export function Container(props: Props) {
  return (
    <div style={props.style} className={`Container ${props.className || ''}`}>
      {props.children}
    </div>
  )
}
