import './Button.css'

interface LinkButtonProps {
  type: 'Link'
  url: string
  label: string
  target?: React.HTMLAttributeAnchorTarget
  rel?: React.AnchorHTMLAttributes<HTMLAnchorElement>['rel']
}

interface ButtonProps {
  type: 'Button'
  action: () => unknown
  label: string
}

type Props = LinkButtonProps | ButtonProps

export function Button(props: Props) {
  switch (props.type) {
    case 'Link':
      return (
        <p className="Button">
          <a href={props.url} target={props.target} rel={props.rel}>
            {props.label}
          </a>
        </p>
      )
    case 'Button':
      return (
        <p className="Button">
          <button onClick={props.action}>{props.label}</button>
        </p>
      )
  }
}
